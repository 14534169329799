body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  background: black;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Canela";
  src: url("assets/fonts/Canela-Regular-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-SemiBold-slnt=0.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-ExtraLight-slnt=0.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Bold-slnt=0.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Black-slnt=0.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Medium-slnt=0.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Thin-slnt=0.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Regular-slnt=0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Light-slnt=0.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-ExtraBold-slnt=0.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
